import { responsiveMedia } from '@cheese-fondue/helpers/media-queries';
import { responsiveProp } from '@cheese-fondue/helpers/responsiveProp';
import { SPACING } from '@cheese-fondue/styles';
import { typography, TypographyVariants } from '@cheese-fondue/styles/typography';
import React, { ReactElement, ReactNode } from 'react';
import styled, { css } from 'styled-components';

export type PaddingVariants =
  | 'none'
  | 'half'
  | 'one'
  | 'two'
  | 'three'
  | 'four'
  | 'five'
  | 'six'
  | 'seven'
  | 'eight'
  | 'twelve'
  | 'fifteen';

export type textAlign = 'center' | 'left' | 'right';

type HTMLTagNames = keyof HTMLElementTagNameMap;
export interface TextProps {
  as: HTMLTagNames;
  size: TypographyVariants | TypographyVariants[];
  children: ReactNode;
  mt?: PaddingVariants | PaddingVariants[];
  mb?: PaddingVariants | PaddingVariants[];
  ml?: PaddingVariants | PaddingVariants[];
  mr?: PaddingVariants | PaddingVariants[];
  color?: string;
  textAlign?: string;
  maxWidth?: string;
  fontFamily?: string;
  letterSpacing?: string;
  href?: string;
  textDecoration?: string;
  uppercase?: boolean;
  fontWeight?: string;
  target?: string;
  rel?: string;
  opacity?: string;
}

const StyledTextElem = styled.p<
  Pick<
    TextProps,
    | 'as'
    | 'size'
    | 'mt'
    | 'mb'
    | 'mr'
    | 'ml'
    | 'textAlign'
    | 'maxWidth'
    | 'fontFamily'
    | 'letterSpacing'
    | 'textDecoration'
    | 'uppercase'
    | 'fontWeight'
    | 'opacity'
  >
>`
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}

  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}

  ${({ size, mt, mb, ml, mr }) =>
    responsiveMedia.map((media, i) => {
      return media`
        ${typography[size[i]]};
        margin-top: ${mt && mt[i] ? mt[i] : 0}px;
        margin-bottom: ${mb && mb[i] ? mb[i] : 0}px;
        margin-left: ${ml && ml[i] ? ml[i] : 0}px;
        margin-right: ${mr && mr[i] ? mr[i] : 0}px;
      `;
    })}
  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `}

  ${props =>
    props.fontFamily &&
    css`
      font-family: ${props.fontFamily};
    `}

  ${props =>
    props.letterSpacing &&
    css`
      letter-spacing: ${props.letterSpacing};
    `}

  ${props =>
    props.textDecoration &&
    css`
      text-decoration: ${props.textDecoration};
    `}

  ${props =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${props =>
    props.fontWeight &&
    css`
      font-weight: ${props.fontWeight};
    `}

    ${props =>
    props.opacity &&
    css`
      opacity: ${props.opacity};
    `}
`;

export const getMargin = (value?: PaddingVariants): number | undefined => {
  if (value && value in SPACING) {
    return SPACING[value];
  }

  return 0;
};

export const Text = ({
  children,
  size,
  as,
  mt = 'none',
  mb = 'none',
  mr = 'none',
  ml = 'none',
  textAlign,
  maxWidth,
  fontFamily,
  letterSpacing,
  textDecoration,
  uppercase,
  fontWeight,
  opacity,
  ...rest
}: TextProps): ReactElement => {
  const responsiveSize = responsiveProp({ prop: size });
  const responsiveMt = responsiveProp({ prop: mt, mapper: val => getMargin(val) });
  const responsiveMb = responsiveProp({ prop: mb, mapper: val => getMargin(val) });
  const responsiveMl = responsiveProp({ prop: ml, mapper: val => getMargin(val) });
  const responsiveMr = responsiveProp({ prop: mr, mapper: val => getMargin(val) });

  return (
    <StyledTextElem
      as={as}
      size={responsiveSize}
      mt={responsiveMt}
      mb={responsiveMb}
      ml={responsiveMl}
      mr={responsiveMr}
      textAlign={textAlign}
      maxWidth={maxWidth}
      fontFamily={fontFamily}
      letterSpacing={letterSpacing}
      textDecoration={textDecoration}
      uppercase={uppercase}
      fontWeight={fontWeight}
      opacity={opacity}
      {...rest}
    >
      {children}
    </StyledTextElem>
  );
};
