import React from 'react';
import { Text } from '@cheese-fondue/components/atoms/text';
import { COLORS } from '@cheese-fondue/styles';
import { Logo } from './logoTextLinkStyles';
import { FluidObject } from 'gatsby-image';

export interface LogoTextLinkProps {
  imageData: FluidObject;
  imageAltText: string;
  text: string;
  link: {
    linkText: string;
    linkPath: string;
    target?: string;
  };
}

export const LogoTextLink = ({ imageData, imageAltText, text, link }: LogoTextLinkProps): React.ReactElement => {
  return (
    <div>
      <Logo src={imageData.src} altText={imageAltText} />
      <Text as="p" size={['p']} color={COLORS.blue400} mb={['one', 'two']} mt={['one', 'two']}>
        {text}
      </Text>
      <Text
        as="a"
        target={link.target}
        href={link.linkPath}
        size={['p']}
        color={COLORS.blue400}
        rel="noopener noreferrer"
      >
        {link.linkText}
      </Text>
    </div>
  );
};
