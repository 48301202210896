import React from 'react';
import styled, { css } from 'styled-components';

import { SpacingVariants, SizeVariants } from '@cheese-fondue/styles/theme-constants';
import { responsiveMedia } from '@cheese-fondue/helpers/media-queries';
import { responsiveProp, getSpacing, getSize } from '@cheese-fondue/helpers/responsiveProp';

type Size = SizeVariants | string;

export interface ContainerProps {
  children?: React.ReactNode;
  pt?: SpacingVariants | SpacingVariants[];
  pb?: SpacingVariants | SpacingVariants[];
  pl?: SpacingVariants | SpacingVariants[];
  pr?: SpacingVariants | SpacingVariants[];
  centerContent?: boolean;
  size?: Size | Size[];
}

const StyledContainer = styled.div<ContainerProps>`
  margin: 0 auto;
  width: 100%;

  ${({ centerContent }) =>
    centerContent &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `}

  ${({ pb, pt, pr, pl, size }) =>
    responsiveMedia.map((media, i) => {
      return media`
        padding-top: ${pt?.[i] || 0}px;
        padding-bottom: ${pb?.[i] || 0}px;
        padding-right: ${pr?.[i] || 0}px;
        padding-left: ${pl?.[i] || 0}px;
        max-width: ${size?.[i]}
      `;
    })}
`;

export const Container = (props: ContainerProps): React.ReactElement => {
  const { children, pt, pb, pr, pl, size = 'large', centerContent = false, ...rest } = props;

  const responsivePt = responsiveProp({ prop: pt, mapper: getSpacing });
  const responsivePb = responsiveProp({ prop: pb, mapper: getSpacing });
  const responsivePl = responsiveProp({ prop: pl, mapper: getSpacing });
  const responsivePr = responsiveProp({ prop: pr, mapper: getSpacing });
  const responsiveSize = responsiveProp({ prop: size, mapper: getSize });

  return (
    <StyledContainer
      {...{
        pt: responsivePt,
        pb: responsivePb,
        pr: responsivePr,
        pl: responsivePl,
        centerContent,
        size: responsiveSize,
      }}
      {...rest}
    >
      {children}
    </StyledContainer>
  );
};
