import { SPACING } from '@cheese-fondue/styles';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

interface Props {
  toggled: boolean;
  toggleHandler: () => void;
  defaultColor: string;
  toggledColor: string;
}

export interface NavComponentProps {
  readonly toggled: boolean;
  readonly defaultColor: string;
  readonly toggledColor: string;
}

const BurgerNav = styled.div`
  position: relative;
  width: 1.5rem;
  height: 1rem;
  transition-duration: 0.5s;
  outline-offset: ${SPACING.one / 2}px;
  cursor: pointer;

  :focus {
    outline: none;
  }

  :focus-visible {
    outline: initial;
  }
`;

const Icon = styled.div<NavComponentProps>`
  transition-duration: 0.5s;
  position: absolute;
  height: 0px;
  width: 2rem;
  top: 0;

  &:before {
    transition-duration: 0.5s;
    position: absolute;
    width: 1.5rem;
    height: 2px;
    background-color: ${props => (props.toggled ? props.toggledColor : props.defaultColor)};
    content: '';
  }

  &:after {
    transition-duration: 0.5s;
    position: absolute;
    width: 1.5rem;
    height: 2px;
    background-color: ${props => (props.toggled ? props.toggledColor : props.defaultColor)};
    content: '';
    top: 1rem;
  }

  ${({ toggled }) =>
    toggled &&
    `
    &:before {
        transform: rotateZ(45deg) scaleX(1) translate(5px, 5px);
    }

    &:after {
        transform: rotateZ(-45deg) scaleX(1) translate(6px, -6px);
    }
    &:hover {
        cursor: pointer;
    }
  `}
`;

export const BurgerNavToggle = ({ toggled, toggleHandler, defaultColor, toggledColor }: Props): ReactElement => {
  const onClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    toggleHandler();
  };

  return (
    <BurgerNav
      onClick={onClick}
      role="button"
      aria-label="Toggle navigation"
      tabIndex={0}
      onKeyPress={e => {
        e.preventDefault();
        toggleHandler();
      }}
    >
      <Icon toggled={toggled} defaultColor={defaultColor} toggledColor={toggledColor} />
    </BurgerNav>
  );
};

export type BurgerNavProps = Props;
