import { useViewportScroll } from 'framer-motion';
import { Link } from 'gatsby';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { COLORS } from '../../styles/theme-constants';
import { DelphiaWordMark } from '../atoms';
import LogoAndWordMark from './../header/LogoAndWordMark';

import { LogoContainer, NavWrapper, NavBackground } from './../navbar/navbarStyles';
import { SmallHeaderInfoQuery } from 'graphql-types';

export interface NavBarProps {
  whiteOnGreen?: boolean;
  blackOnPurple?: boolean;
  blueOnOrange?: boolean;
  whiteOnBlue?: boolean;
  navItems: SmallHeaderInfoQuery['allSmallHeaderNavigationYaml']['edges'];
}

const getLogo = (
  whiteOnGreen?: boolean,
  whiteOnBlue?: boolean,
  blueOnOrange?: boolean,
  blackOnPurple?: boolean,
): ReactElement => {
  if (whiteOnGreen) {
    return <LogoAndWordMark logoColor={COLORS.white} wordmarkColor={COLORS.white} />;
  } else if (blackOnPurple) {
    return <LogoAndWordMark logoColor={COLORS.black} wordmarkColor={COLORS.black} />;
  } else if (whiteOnBlue) {
    return <LogoAndWordMark logoColor={COLORS.orange300} wordmarkColor={COLORS.white} />;
  } else if (blueOnOrange) {
    return <LogoAndWordMark logoColor={COLORS.black} wordmarkColor={COLORS.black} />;
  } else {
    return <DelphiaWordMark />;
  }
};

export const NavBar = (props: NavBarProps): ReactElement => {
  return <PureNavBar {...props} />;
};

export const PureNavBar = ({
  whiteOnGreen = false,
  blackOnPurple = false,
  blueOnOrange = false,
  whiteOnBlue = false,
}: NavBarProps): ReactElement => {
  const HOME_PATH = '/';

  const posY = useRef(0);
  const [scrolledPassedThreshold, setScrolledPassedThreshold] = useState(false);
  const { scrollY } = useViewportScroll();
  const threshold = 10;

  useEffect(() => {
    return scrollY.onChange(v => {
      if (v === posY.current) return;
      setScrolledPassedThreshold(v > threshold);
      posY.current = v;
    });
  }, [posY, scrolledPassedThreshold, scrollY]);

  return (
    <>
      <NavBackground
        visible={!scrolledPassedThreshold}
        blackOnPurple={blackOnPurple}
        whiteOnGreen={whiteOnGreen}
        blueOnOrange={blueOnOrange}
        whiteOnBlue={whiteOnBlue}
      />
      <NavWrapper>
        <LogoContainer>
          <Link to={HOME_PATH} title="home">
            {getLogo(whiteOnGreen, whiteOnBlue, blackOnPurple, blueOnOrange)}
          </Link>
        </LogoContainer>
      </NavWrapper>
    </>
  );
};
