import React from 'react';
import { CheckboxProps } from './checkbox-props';
import { CheckboxIcon, HiddenCheckbox, Label, StyledCheckbox } from './checkbox-styles';

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref): React.ReactElement => {
    const {
      children,
      defaultChecked,
      checked,
      disabled,
      id,
      isError,
      isRequired = true,
      name,
      value,
      size = 'medium',
      onChange,
      onBlur,
      onFocus,
      ...rest
    } = props;

    return (
      <StyledCheckbox aria-describedby="label" aria-invalid={isError} aria-required={isRequired} {...rest}>
        <div className="wrapper">
          <HiddenCheckbox
            ref={ref}
            type="checkbox"
            {...{ defaultChecked, checked, disabled, id, onChange, onBlur, onFocus, name, value }}
          />
          <CheckboxIcon {...{ isError, size }} />
        </div>
        {children && (
          <Label id="label" htmlFor={id}>
            {children}
          </Label>
        )}
      </StyledCheckbox>
    );
  },
);

Checkbox.displayName = 'Checkbox';
