import styled, { AnyStyledComponent } from 'styled-components';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const makeScreenReaderOnly = (Component: AnyStyledComponent) => styled(Component)`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;

const span = styled.span``;
export const ScreenReaderLabel = makeScreenReaderOnly(span);
