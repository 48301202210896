import { BREAKPOINTS, SPACING, SpacingVariants, SIZES } from '../styles/theme-constants';

const addValues = ({ propArray }): any[] => {
  const lastKnownValue = propArray[propArray.length - 1];
  while (propArray.length < BREAKPOINTS.length) {
    propArray.push(lastKnownValue);
  }
  return propArray;
};

export const makeArray = (prop?: any | any[]): any[] => {
  if (Array.isArray(prop)) return prop;
  else return [prop];
};

const getArray = (prop?: any, defaultProp?: any): any[] => {
  if (prop === null || prop === undefined) {
    return makeArray(defaultProp);
  }
  return makeArray(prop);
};

interface ResponsiveProp {
  prop?: any;
  defaultProp?: any;
  mapper?: (val: any) => any;
}

export const mapResponsive = (prop: any[], mapper: (val: any) => any): any[] => {
  return prop.map(item => {
    if (item === null) {
      return null;
    }
    return mapper(item);
  });
};

export const responsiveProp = ({ prop, defaultProp, mapper = val => val }: ResponsiveProp): any[] => {
  const breakpointsLength = BREAKPOINTS.length;
  let propArray = getArray(prop, defaultProp);

  if (propArray.length < breakpointsLength) {
    propArray = addValues({ propArray });
  }

  if (propArray.length > breakpointsLength) {
    propArray = propArray.splice(0, breakpointsLength);
  }

  return mapResponsive(propArray, mapper);
};

export const getSpacing = (value?: SpacingVariants): number | undefined => {
  if (value && value in SPACING) {
    return SPACING[value];
  }

  return 0;
};

export const getSize = (value: string): string => {
  return value in SIZES ? SIZES[value] + 'px' : value;
};
