import { COLORS, SPACING } from '@cheese-fondue/styles';
import { typography } from '@cheese-fondue/styles/typography';
import React from 'react';
import styled from 'styled-components';
import { Input, InputProps } from './input';
import { colors } from './input-styles';

export const Wrapper = styled.div`
  display: block;
`;

export const InputLabel = styled.label<{ colorScheme?: 'light' | 'dark' }>`
  ${typography.preSmall}
  display:block;
  margin: 0 0 ${SPACING.one / 4}px;
  color: ${({ colorScheme = 'light' }) => colors[colorScheme]};
`;

export const ErrorMessage = styled.div`
  ${typography.pSmall}
  color: ${COLORS.red};
  margin: 8px 0 0 0;
  text-align: left;
`;

export interface InputFieldProps extends Omit<InputProps, 'id'> {
  label?: string;
  errorMessage?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  id: string;
}

export const InputField = (props: InputFieldProps): React.ReactElement => {
  const {
    label,
    errorMessage,
    inputRef,
    id,
    isError,
    size,
    colorScheme,
    placeholder,
    type,
    readOnly,
    disabled,
    name,
    onFocus,
    onBlur,
    onChange,
    defaultValue,
    variant,
    ...rest
  } = props;

  const inputProps = {
    size,
    colorScheme,
    placeholder,
    type,
    readOnly,
    disabled,
    name,
    defaultValue,
    onFocus,
    onBlur,
    onChange,
    isError: isError || !!errorMessage,
    variant,
  } as InputProps;

  return (
    <Wrapper role="group" {...rest}>
      {label && (
        <InputLabel htmlFor={`${id}-label`} colorScheme={colorScheme}>
          {label}
        </InputLabel>
      )}
      <Input id={id} ref={inputRef} {...inputProps} />
      {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  );
};
