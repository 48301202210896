import { graphql, useStaticQuery } from 'gatsby';
import { HeaderInfoQuery } from 'graphql-types';
export const useHeaderNavData = (): HeaderInfoQuery => {
  const data = useStaticQuery(
    graphql`
      query HeaderInfo {
        allMainNavigationYaml {
          edges {
            node {
              id
              label
              path
            }
          }
        }
      }
    `,
  );
  return data;
};
