import React, { ReactElement } from 'react';

interface LogoAndWordmarkProps {
  logoColor: string;
  wordmarkColor: string;
}

const LogoAndWordMark = (props: LogoAndWordmarkProps): ReactElement => {
  const { logoColor, wordmarkColor } = props;

  return (
    <svg width="116" height="30" viewBox="0 0 116 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Delphia</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6364 29.9867V30C16.7574 30 17.8376 29.8251 18.8511 29.501C25.2693 27.801 30 21.9529 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C15.2132 30 15.4253 29.9956 15.6364 29.9867ZM9.12554 3.19629C8.09836 3.70848 7.14744 4.35081 6.29437 5.10168L6.29437 24.8983C7.14744 25.6492 8.09836 26.2915 9.12555 26.8037L9.12554 3.19629ZM10.9437 2.45406L10.9437 27.5459C11.9178 27.8606 12.9415 28.0651 14 28.1445L14 1.85554C12.9415 1.93494 11.9178 2.13935 10.9437 2.45406ZM1.81818 15C1.81818 12.019 2.8077 9.26912 4.47619 7.0609L4.47619 22.9391C2.8077 20.7309 1.81818 17.981 1.81818 15ZM15.8182 28.1568C16.043 28.1431 16.2663 28.1237 16.4879 28.0988C18.4902 27.7027 20 25.9367 20 23.8182C20 21.4691 18.1438 19.5536 15.8182 19.4583V28.1568ZM15.8182 17.639C19.1482 17.7352 21.8182 20.4649 21.8182 23.8182C21.8182 24.5797 21.6805 25.309 21.4287 25.9827C23.2291 24.384 24.3636 22.0517 24.3636 19.4545C24.3636 14.6954 20.5542 10.8259 15.8182 10.7291L15.8182 17.639ZM15.8182 8.91063C21.5584 9.00767 26.1818 13.6912 26.1818 19.4545C26.1818 20.6122 25.9953 21.7264 25.6506 22.7685C27.2423 20.59 28.1818 17.9047 28.1818 15C28.1818 7.99466 22.7172 2.26564 15.8182 1.84316V8.91063Z"
        fill={logoColor}
      />
      <path
        d="M39 22.2717H42.6422C46.1286 22.2717 48.9528 20.146 48.9528 15.4461C48.9528 10.7462 46.1286 8.62053 42.6422 8.62053H39V22.2717ZM41.123 20.2825V10.6097H42.4474C44.7068 10.6097 46.7519 11.7213 46.7519 15.4461C46.7519 19.1709 44.7068 20.2825 42.4474 20.2825H41.123Z"
        fill={wordmarkColor}
      />
      <path
        d="M55.7915 22.4277C57.895 22.4277 59.3363 21.5111 60.2517 19.8145L58.6351 18.9759C58.0508 19.9705 57.1549 20.653 55.8499 20.653C54.2333 20.653 53.1816 19.4439 53.1037 17.9228H60.4075V17.0842C60.4075 14.7245 58.9468 12.2868 55.7915 12.2868C52.6362 12.2868 51.078 14.8025 51.078 17.3573C51.078 19.951 52.6752 22.4277 55.7915 22.4277ZM53.1816 16.2652C53.4348 15.0171 54.3307 14.0225 55.7915 14.0225C57.2912 14.0225 58.1287 14.9586 58.4014 16.2652H53.1816Z"
        fill={wordmarkColor}
      />
      <path
        d="M67.7721 22.4277C69.7783 22.4277 71.1806 21.2381 71.5312 18.8589L69.7393 18.4884C69.5251 19.795 68.8629 20.5165 67.7721 20.5165C66.7204 20.5165 65.9997 19.951 65.9997 18.2933V8.62053H61.3642V10.3952H63.9741V18.5664C63.9741 21.0431 65.5128 22.4277 67.7721 22.4277Z"
        fill={wordmarkColor}
      />
      <path
        d="M73.676 26.25H75.7016V21.1991C76.1885 21.8426 77.2208 22.4277 78.3894 22.4277C81.311 22.4277 82.8886 20.3605 82.8886 17.3573C82.8886 14.3345 81.311 12.2868 78.3894 12.2868C77.1234 12.2868 76.1106 12.9694 75.7016 13.5934V12.4429H73.676V26.25ZM78.1557 20.653C76.578 20.653 75.5263 19.3854 75.5263 17.3573C75.5263 15.3291 76.578 14.0615 78.1557 14.0615C79.7138 14.0615 80.7851 15.3291 80.7851 17.3573C80.7851 19.3854 79.7138 20.653 78.1557 20.653Z"
        fill={wordmarkColor}
      />
      <path
        d="M85.6956 22.2717H87.7212V18.1373C87.7212 15.4071 89.1041 14.0615 90.4285 14.0615C91.5192 14.0615 92.0646 14.666 92.0646 15.9726V22.2717H94.0902V15.3681C94.0902 13.3789 93.0384 12.2868 91.0518 12.2868C89.5715 12.2868 88.3834 13.1059 87.7212 14.1395V8.46452H85.6956V22.2717Z"
        fill={wordmarkColor}
      />
      <path
        d="M100.948 11.1557C101.786 11.1557 102.409 10.5317 102.409 9.71262C102.409 8.89355 101.786 8.25 100.948 8.25C100.13 8.25 99.5071 8.89355 99.5071 9.71262C99.5071 10.5317 100.13 11.1557 100.948 11.1557ZM96.8387 22.2717H105.409V20.5165H102.078V12.4429H97.2867V14.198H100.052V20.5165H96.8387V22.2717Z"
        fill={wordmarkColor}
      />
      <path
        d="M110.416 22.4277C111.838 22.4277 113.007 21.8231 113.65 20.9456L113.903 22.2717H115.5V16.2262C115.5 13.3009 113.864 12.2868 111.546 12.2868C109.735 12.2868 108.255 13.3594 107.787 15.0366L109.54 15.5241C109.852 14.5295 110.631 14.003 111.546 14.003C112.578 14.003 113.533 14.4905 113.533 15.7971V16.2262H111.721C109.131 16.2262 107.398 17.3768 107.398 19.4439C107.398 21.4136 108.761 22.4277 110.416 22.4277ZM110.709 20.692C109.852 20.692 109.345 20.146 109.345 19.4244C109.345 18.3908 110.163 17.8253 111.566 17.8253H113.513V18.0983C113.513 19.8925 111.936 20.692 110.709 20.692Z"
        fill={wordmarkColor}
      />
    </svg>
  );
};

export default LogoAndWordMark;
