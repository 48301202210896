import React, { ReactElement } from 'react';
import { ButtonProps, InternalButtonProps } from './button-props';
import { StyledButton, StyledButtonLink } from './button-styles';
import { FONTS } from '../../../styles';
import styled from 'styled-components';

interface LinkProps {
  to: string;
  title: string;
}

export type ButtonLinkTypes = ButtonProps & LinkProps;

export const Button = ({ children, ...props }: ButtonProps): ReactElement => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

export const ButtonLink = ({
  title,
  to,
  children,
  className,
  ...props
}: InternalButtonProps & LinkProps): ReactElement => {
  return (
    <StyledButtonLink to={to} title={title} className={className} {...props}>
      {children}
    </StyledButtonLink>
  );
};

export const PlainFontButtonLink = styled(ButtonLink)`
  font-family: ${FONTS.primary};
  text-transform: none;
  font-weight: bold;
  letter-spacing: 1.5px;
`;

export const WideButtonLink = styled(PlainFontButtonLink)`
  padding: 0 40px;
`;
