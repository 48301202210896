import React from 'react';
import styled, { css } from 'styled-components';

import { speckledBackground as getSpeckledBackground } from '@cheese-fondue/styles/backgrounds';
import { SpacingVariants } from '@cheese-fondue/styles/theme-constants';
import { responsiveMedia } from '@cheese-fondue/helpers/media-queries';
import { responsiveProp, getSpacing } from '@cheese-fondue/helpers/responsiveProp';

export interface SectionProps {
  backgroundColor?: string;
  speckledBackground?: string;
  children?: React.ReactNode;
  as?: keyof HTMLElementTagNameMap;
  pt?: SpacingVariants | SpacingVariants[];
  pb?: SpacingVariants | SpacingVariants[];
  pl?: SpacingVariants | SpacingVariants[];
  pr?: SpacingVariants | SpacingVariants[];
}

const StyledSection = styled.section<SectionProps>`
  ${({ backgroundColor = undefined }) =>
    css`
      background: ${backgroundColor};
    `}

  ${({ speckledBackground }) => (speckledBackground ? getSpeckledBackground(speckledBackground) : null)}

  ${({ pb, pt, pr, pl }) =>
    responsiveMedia.map((media, i) => {
      return media`
        padding-top: ${pt?.[i] || null}px;
        padding-bottom: ${pb?.[i] || null}px;
        padding-right: ${pr?.[i] || null}px;
        padding-left: ${pl?.[i] || null}px;
      `;
    })}
`;

const defaultPaddingYProps = ['four', 'eight'];
const defaultPaddingXProps = ['one', 'none'];
export const Section = (props: SectionProps): React.ReactElement => {
  const {
    backgroundColor,
    speckledBackground,
    children,
    as,
    pt = defaultPaddingYProps,
    pb = defaultPaddingYProps,
    pr = defaultPaddingXProps,
    pl = defaultPaddingXProps,
    ...rest
  } = props;

  const responsivePt = responsiveProp({ prop: pt, mapper: getSpacing });
  const responsivePb = responsiveProp({ prop: pb, mapper: getSpacing });
  const responsivePl = responsiveProp({ prop: pl, mapper: getSpacing });
  const responsivePr = responsiveProp({ prop: pr, mapper: getSpacing });

  return (
    <StyledSection
      {...{
        as,
        pt: responsivePt,
        pb: responsivePb,
        pr: responsivePr,
        pl: responsivePl,
        backgroundColor,
        speckledBackground,
      }}
      {...rest}
    >
      {children}
    </StyledSection>
  );
};
