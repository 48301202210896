import Img, { FluidObject, GatsbyImageFluidProps } from 'gatsby-image';
import React, { ReactElement } from 'react';

export interface ImageProps extends Omit<GatsbyImageFluidProps, 'fluid'> {
  altText?: string;
  imageData: FluidObject;
}

export const Image = ({ altText = '', imageData, ...props }: ImageProps): ReactElement => (
  <Img alt={altText} {...props} fluid={imageData} />
);
