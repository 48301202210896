import { ANIMATION_CURVES } from '@cheese-fondue/styles/theme-constants';
import { motion, useReducedMotion, useTransform, useViewportScroll } from 'framer-motion';
import { TransformOptions } from 'framer-motion/types/utils/transform';
import throttle from 'lodash.throttle';
import React, { ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
export interface ParallaxWrapperProps {
  children: ReactNode;
  yOffset?: number;
  speed?: number;
  ease?: TransformOptions<number>;
  triggerPoint?: number;
  triggerPosition?: number;
  transformEndPosition?: number;
  cssProp?: string;
  startValue?: number;
  endValue?: number;
  disableOnMobile?: boolean;
  className?: string;
}

const MotionDiv = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  will-change: translateZ, translateY, opacity;
`;

const isMobileViewport = (): boolean =>
  typeof window !== 'undefined' && window.matchMedia ? window.matchMedia('(max-width: 767px)').matches : false;

export const ParallaxWrapper = ({
  children,
  yOffset = 0, // if 0 then we use the offsetHeight of the ParallaxWrapper element
  speed = 0.5, // 1 = same as scroll. You scroll 100px, we move the elem the same amount
  ease = ANIMATION_CURVES.bezier as TransformOptions<number>,
  triggerPoint = 0, // value between 0 and 1 (top and bottom of the window), point to start animation
  cssProp = 'y',
  startValue,
  endValue,
  disableOnMobile = false,
  triggerPosition, // y scroll position, if set triggerPoint is ignored, optional
  transformEndPosition, // y scroll position, optional
  className,
  ...rest
}: ParallaxWrapperProps): ReactElement => {
  const shouldReduceMotion = useReducedMotion();
  const { scrollY } = useViewportScroll();
  const ref = useRef<HTMLDivElement>(null);
  const [elementTop, setElementTop] = useState(0);
  const [elementHeight, setElementHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const setValues = (): void => {
    if (ref && ref.current) {
      const bounds = ref.current.getBoundingClientRect();
      setElementTop(window.scrollY + bounds.top ?? 0);
      setElementHeight(ref.current.offsetHeight ?? 0);
      setClientHeight(window.innerHeight);
      setIsMobile(isMobileViewport());
    }
  };

  const throttledSetValues = useCallback(throttle(setValues, 500), [
    yOffset,
    speed,
    startValue,
    endValue,
    triggerPosition,
    triggerPoint,
  ]);

  useEffect(() => {
    if (!ref) return;

    setValues();
    document.addEventListener('load', setValues);
    window.addEventListener('resize', throttledSetValues);

    return () => {
      document.removeEventListener('load', setValues);
      window.removeEventListener('resize', throttledSetValues);
      throttledSetValues.cancel();
    };
  }, [ref, throttledSetValues]);

  const yOffsetParsed = yOffset === 0 ? elementHeight : yOffset;
  const transformInitialValue = Number.isFinite(triggerPosition)
    ? triggerPosition
    : elementTop - clientHeight * triggerPoint;
  const transformFinalValue = Number.isFinite(transformEndPosition)
    ? transformEndPosition
    : elementTop + yOffsetParsed;
  const range = [transformInitialValue, transformFinalValue];
  const outputRange =
    Number.isFinite(startValue) && Number.isFinite(endValue) ? [startValue, endValue] : [0, -yOffsetParsed * speed];
  const value = useTransform(scrollY, range, outputRange, ease);

  return (
    <MotionDiv
      ref={ref}
      className={className}
      initial={{ [cssProp]: 0 }}
      style={{ [cssProp]: shouldReduceMotion || (disableOnMobile && isMobile) ? 0 : value }}
      {...rest}
    >
      {children}
    </MotionDiv>
  );
};
