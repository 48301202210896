import { Link } from 'gatsby';
import styled from 'styled-components';
import { media } from '../../helpers/media-queries';
import { SIZES } from '../../styles';
import { COLORS } from '../../styles/theme-constants';

export interface NavComponentProps {
  readonly dark: boolean;
  readonly visible?: boolean;
  readonly blueOnOrange?: boolean;
  readonly blackOnPurple?: boolean;
}

export const NavListLink = styled(Link)<{ dark?: boolean }>`
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  padding: 2px 0px 2px 0px;

  &.active {
    position: relative;
    font-weight: bold;

    :after {
      position: absolute;
      width: 100%;
      height: 1px;
      content: '';
      background: ${props => (props.dark ? COLORS.orange100 : COLORS.blue200)};
      bottom: -2px;
      left: 0;
      display: block;

      ${media.medium`
          background: ${COLORS.white};
      `}
    }
  }
`;

export const HeaderContainer = styled.header<NavComponentProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 3.75rem;
  ${media.medium`
    height: 4.5rem;
  `}

  ${NavListLink} {
    text-decoration: none;
    color: ${props => (props.dark ? COLORS.white : COLORS.blue200)};

    /* Disable css lint bug where all blocks in the same file are evulated as one */
    /* stylelint-disable no-descending-specificity */
    &:hover {
      color: ${props => (props.dark ? COLORS.orange200 : COLORS.blue400)};
    }
  }
`;

export const NavWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: stretch; /* Default */
  justify-content: space-between;
  z-index: 1;
  height: 100%;
  max-width: ${SIZES.large}px;
  margin: 0 auto;
  padding: 0 2rem;
  ${media.medium`
    padding: 0 1rem;
    `}
  ${media.large`
  padding: 0 0;
    `}
`;

export const NavBackground = styled.div<NavComponentProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props =>
    props.dark
      ? COLORS.blue400
      : props.blueOnOrange
      ? COLORS.orange300
      : props.blackOnPurple
      ? COLORS.white100
      : COLORS.white};
  opacity: ${props => (props.visible ? 0 : 1)};
  transition: opacity 0.2s;
`;

type LogoContainerProps = Omit<NavComponentProps, 'dark'>;
export const LogoContainer = styled.span<LogoContainerProps>`
  margin: auto 0;
  display: initial;

  ${media.medium`
    height:100%;
    width:fit-content;
    left:0;
    right:0;
    top:0;
    display:flex;
    align-items:center;
  `}

  a {
    display: flex;
  }

  svg path {
    will-change: fill;
    transition: fill 0.3s ease ${props => (props.visible ? '0' : '1s')};
  }
`;

export const NavContainer = styled.nav<NavComponentProps>`
  display: none;
  margin: auto 0;

  ${media.medium`
      display: block;
    `}

  ul {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    list-style-type: none;
    width: 100%;
    margin: auto;
    padding: 0;

    ${media.large`
        gap: 2rem;
      `}
  }

  li {
    list-style-type: none;
    margin: auto;
  }
`;

export const HamburgerNavIcon = styled.div`
  margin: auto 0;
  ${media.medium`display: none;`};
`;

export const SmallScreenNav = styled.div<NavComponentProps>`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  background: ${props => (props.dark ? COLORS.white : COLORS.blue400)};

  ${media.medium`display: none;`};

  ${NavListLink} {
    text-decoration: none;
    color: ${props => (props.dark ? COLORS.blue400 : COLORS.orange100)};

    &:hover {
      color: ${props => (props.dark ? COLORS.blue200 : COLORS.orange200)};
    }
  }
`;

export const SmallScreenNavItems = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  padding: 5rem 1rem 1rem 1rem;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
    margin: auto;
    padding: 4px 0;
  }

  > div:last-child {
    margin-top: auto;
    margin-bottom: 0;
  }

  /* Should be replaced by block prop once available on Button comp */
  button {
    width: 100%;
  }
`;
