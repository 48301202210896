import { BREAKPOINTS } from '../../styles/theme-constants';

export const GRID_SETTINGS = {
  breakpoints: BREAKPOINTS,
  colspan: [12],
  left: ['0'],
  right: ['0'],
  top: '0',
  bottom: '0',
  gutterX: ['0'],
  gutterY: '0',
  control: process.env.NODE_ENV !== 'production',
  controlColor: 'rgba(118, 19, 120, 0.2)',
};

export const BOX_SETTINGS = {
  height: ['300px'],
};
