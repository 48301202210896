import { WindowLocation } from '@reach/router';
import { motion, useViewportScroll } from 'framer-motion';
import { Link } from 'gatsby';
import { HeaderInfoQuery } from 'graphql-types';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { COLORS } from '../../styles/theme-constants';
import { DelphiaWordMark } from '../atoms';
import { useHeaderNavData } from './header-graphql-query';
import LogoAndWordMark from './LogoAndWordMark';

import { HeaderContainer, LogoContainer, NavBackground, NavWrapper } from './header-styling';

export interface Props {
  location?: WindowLocation;
  whiteOnBlue?: boolean;
  blueOnOrange?: boolean;
}
export interface PureHeaderProps extends Props {
  data: HeaderInfoQuery;
}

const getLogo = (isNavToggled: boolean, whiteOnBlue: boolean, blueOnOrange?: boolean): ReactElement => {
  if (blueOnOrange && !isNavToggled) {
    return <LogoAndWordMark logoColor={COLORS.blue400} wordmarkColor={COLORS.blue400} />;
  } else if (blueOnOrange && isNavToggled) {
    return <LogoAndWordMark logoColor={COLORS.orange300} wordmarkColor={COLORS.white} />;
  }

  if (!whiteOnBlue && isNavToggled) {
    return <LogoAndWordMark logoColor={COLORS.orange300} wordmarkColor={COLORS.white} />;
  } else if (!whiteOnBlue && !isNavToggled) {
    return <DelphiaWordMark />;
  }

  if (whiteOnBlue && isNavToggled) {
    return <DelphiaWordMark />;
  }

  return <LogoAndWordMark logoColor={COLORS.orange300} wordmarkColor={COLORS.white} />;
};

export const Header = (props: Props): ReactElement => {
  const data = useHeaderNavData();
  return <PureHeader {...props} data={data} />;
};

export const PureHeader = ({ whiteOnBlue = false, blueOnOrange = false }: PureHeaderProps): ReactElement => {
  const HOME_PATH = '/';

  const posY = useRef(0);
  const [isDownThePage, setIsDownThePage] = useState(false);
  const { scrollY } = useViewportScroll();
  const threshold = 10;
  const [isNavToggled] = useState(false);

  useEffect(() => {
    return scrollY.onChange(v => {
      if (v === posY.current) return;
      setIsDownThePage(v > threshold);
      posY.current = v;
    });
  }, [threshold, posY, isDownThePage, scrollY]);

  useEffect(() => {
    if (isNavToggled) {
      document.body.classList.add('lockScroll');
    } else {
      document.body.classList.remove('lockScroll');
    }
  }, [isNavToggled]);

  return (
    <>
      <HeaderContainer
        id="header-content"
        as={motion.header}
        transition={{ ease: 'easeInOut' }}
        dark={whiteOnBlue}
        blueOnOrange={blueOnOrange}
      >
        <NavWrapper>
          <LogoContainer visible={isNavToggled}>
            <Link to={HOME_PATH} title="home">
              {getLogo(isNavToggled, whiteOnBlue, blueOnOrange)}
            </Link>
          </LogoContainer>
        </NavWrapper>
        <NavBackground dark={whiteOnBlue} visible={!isDownThePage} blueOnOrange={blueOnOrange} />
      </HeaderContainer>
    </>
  );
};
