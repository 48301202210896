import styled from 'styled-components';
import { media } from '../../helpers';
import { SPACING } from '../../styles';
import { typography } from '../../styles/typography';
import { Box } from '../grid';

export const LinkGridListWrapper = styled.div`
  padding: 0 ${SPACING.one}px ${SPACING.four}px ${SPACING.one}px;
  ${media.medium`
    padding: 0 ${SPACING.two}px ${SPACING.four}px ${SPACING.two}px;
  `}
`;

export const Headline = styled.h2`
  ${typography.h2};
  margin: ${SPACING.three}px 0 ${SPACING.two}px 0;

  ${media.medium`
    ${typography.h1XLarge};
    margin: ${SPACING.three}px 0 ${SPACING.three}px 0;
    
  `}
`;

export const ListItemBox = styled(Box)`
  min-height: ${SPACING.five}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export const ListItemWrapper = styled.div`
  margin: 0 auto;
  display: block;
  *:first-child {
    width: 100%;
  }
`;
