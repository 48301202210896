import { COLORS, SIZES, SPACING } from '@cheese-fondue/styles/';
import styled from 'styled-components';
import { media } from '../../helpers/';
import { LinkWrapper } from '../atoms';
import { Grid } from '../grid';
export interface ThemeProps {
  theme?: string;
}

export const FooterWrap = styled.footer`
  background-color: ${COLORS.orange100};
`;

export const FooterContainer = styled.div<ThemeProps>`
  max-width: ${SIZES.large}px;
  margin: 0 auto;
  line-height: ${SPACING.two}px;
  a {
    color: ${props => (props.theme === 'dark' ? COLORS.white : COLORS.black)};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      svg path {
        transition: all 200ms ease-in-out;
        fill: ${COLORS.teal200};
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`;

export const FooterMain = styled.section`
  padding: 0 ${SPACING.two}px ${SPACING.two}px ${SPACING.two}px;
`;

export const TopRow = styled.div<ThemeProps>`
  border-bottom: 2px solid ${props => (props.theme === 'dark' ? COLORS.white : COLORS.black)};
  display: flex;
  flex-direction: column;
  padding: ${SPACING.two}px 0;
  ${media.customBreak(1024)`
    flex-direction: row;
  `}
`;

export const LogoContainer = styled.div`
  margin: 0 0 ${SPACING.two}px;
  width: 100%;
  ${media.medium`
    width: calc(100% / 12 * 8);
  `}
`;

export const NavGridContainer = styled.nav`
  width: 100%;
  ${media.medium`
    width: calc(96% / 12 * 5);
  `}
  ${media.large`
  width: calc(87% / 12 * 5);
`}
`;

export const NavGrid = styled(Grid)<ThemeProps>`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: ${SPACING.three}px;
  grid-template-rows: repeat(6, min-content);
  grid-row-gap: ${SPACING.one * 0.75}px;
  justify-content: start;

  ${media.medium`
    grid-auto-flow: row;
    grid-template-rows: unset;
    grid-template-columns: ${props => (props.theme === 'dark' ? 'repeat(6, min-content)' : 'repeat(5, min-content)')};
  `}
`;

export const SecondRow = styled.div`
  padding-top: ${SPACING.two}px;
  ul li {
    display: inline;
    padding-right: ${SPACING.two}px;

    :last-child {
      padding-right: 0;
    }
  }

  .grid {
    .box {
      ${media.customBreak(1024)`
      grid-column: auto / span 6;
     `}
    }
  }

  ${media.medium`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  `}
`;

export const SocialLinks = styled.nav<ThemeProps>``;

export const FooterLegal = styled.nav`
  grid-column: 1 / -1;
  width: 100%;
  margin-top: ${SPACING.two}px;

  ${media.customBreak(1024)`
      margin-top: 0;
  `}

  ${media.medium`
      margin-left:auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `}

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: -${SPACING.one * 0.5}px -${SPACING.one * 1.5}px;

    li {
      padding: ${SPACING.one * 0.5}px ${SPACING.one * 1.5}px;
    }
  }
`;

export const Copyright = styled.p`
  margin-top: ${SPACING.one}px;
  margin-bottom: 0;
`;

export const LegalText = styled.p`
  font-size: ${SPACING.one - 6}px;
  line-height: ${SPACING.one}px;
  padding-top: ${SPACING.two}px;

  ${media.medium`
      padding-top: 0;
    `}
`;

export const StyledLink = styled(LinkWrapper)`
  white-space: nowrap;

  &.active {
    font-weight: bold;
  }
`;

export const FooterWrapDark = styled.footer`
  background-color: ${COLORS.black};
  color: ${COLORS.white};
`;

export const LegalTextWrapper = styled.div`
  padding-top: ${SPACING.two}px;
`;
