import { graphql, useStaticQuery } from 'gatsby';
import { SmallHeaderInfoQuery } from 'graphql-types';

export const useSmallHeaderNavData = (): SmallHeaderInfoQuery => {
  const data = useStaticQuery(
    graphql`
      query SmallHeaderInfo {
        allSmallHeaderNavigationYaml {
          edges {
            node {
              id
              label
              path
            }
          }
        }
      }
    `,
  );
  return data;
};
