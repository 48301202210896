import { COLORS, SPACING } from '@cheese-fondue/styles';
import styled, { css } from 'styled-components';
import { CheckboxProps } from './checkbox-props';

type HiddenCheckboxProps = Omit<CheckboxProps, 'size' | 'children'>;
type CheckboxIconProps = {
  size?: CheckboxProps['size'];
  isError?: CheckboxProps['isError'];
};

const sizes = {
  small: css`
    transform: scale(0.8);
  `,
  medium: css`
    transform: 'none';
  `,
};

const getStateColor = ({ isError }: HiddenCheckboxProps): string => (isError ? COLORS.red : COLORS.grey);

export const StyledCheckbox = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  .wrapper {
    display: block;
  }
`;

export const CheckboxIcon = styled.div<CheckboxIconProps>`
  border: 1px solid;
  border-color: ${({ isError }) => (isError ? COLORS.red : COLORS.grey)};
  box-shadow: none;
  border-radius: 4px;
  position: relative;
  background-color: #fff;
  width: 20px;
  height: 20px;

  ${({ size = 'medium' }) => sizes[size]};
`;

export const HiddenCheckbox = styled.input<HiddenCheckboxProps>`
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  width: 2px;
  &[disabled] + ${/* sc-selector */ CheckboxIcon} {
    background-color: ${COLORS.grey};
    box-shadow: unset;
  }
  &:checked:focus + ${/* sc-selector */ CheckboxIcon} {
    box-shadow: none;
  }
  &:focus + ${/* sc-selector */ CheckboxIcon} {
    box-shadow: none;
  }

  &:not([disabled]):checked + ${/* sc-selector */ CheckboxIcon} {
    border-color: ${props => getStateColor(props)};
  }

  &:checked + ${/* sc-selector */ CheckboxIcon} {
    border-color: ${COLORS.purple100};
    background: ${COLORS.blue300};

    &::before {
      background-clip: padding-box;
      content: '';
      left: calc(50% - 2px);
      top: calc(50% - 7px);
      position: absolute;
      border: 0.15em solid white;
      border-left-width: 0;
      border-top-width: 0;
      height: 10px;
      transform: rotate(45deg);
      width: 3px;
    }
  }

  &[disabled]:checked + ${/* sc-selector */ CheckboxIcon}::before {
    border-color: ${COLORS.grey};
    opacity: 0.8;
  }
`;

export const Label = styled.div`
  margin-left: ${SPACING.one}px;
`;
