import { Link } from 'gatsby';
import React, { ReactElement, ReactNode } from 'react';

export interface LinkWrapperProps {
  to: string;
  title: string;
  children: ReactNode;
}

export const LinkWrapper = ({ to, title, children, ...props }: LinkWrapperProps): ReactElement => {
  if (to.charAt(0) === '/') {
    return (
      <Link to={to} title={title} {...props}>
        {children}
      </Link>
    );
  }

  return (
    <a href={to} title={title} {...props}>
      {children}
    </a>
  );
};
