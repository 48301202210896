import styled from 'styled-components';

export interface NavComponentProps {
  readonly visible?: boolean;
}

export const HeaderContainer = styled.header<NavComponentProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 4.5rem;
`;
