import { graphql, Link, useStaticQuery } from 'gatsby';
import { FooterInfoQuery, FooterNavigationYaml } from 'graphql-types';
import React, { ReactElement, useMemo } from 'react';
import { ScreenReaderLabel } from '../../helpers/screen-reader-label';
import { DelphiaWordMark } from '../atoms';
import { Box, Grid } from '../grid';
import {
  Copyright,
  FooterContainer,
  FooterLegal,
  FooterMain,
  FooterWrap,
  LogoContainer,
  NavGrid,
  NavGridContainer,
  SecondRow,
  StyledLink,
  TopRow,
} from './footer-styles';

export interface PureFooterProps {
  data: FooterInfoQuery;
}

const renderNavItems = (nodes: FooterInfoQuery['allFooterNavigationYaml']['edges']): React.ReactNode => {
  return nodes.map((e: { node: Pick<FooterNavigationYaml, 'id' | 'label' | 'path'> }) => {
    return (
      <Box cols={[1]} tag="li" key={e.node.id}>
        <StyledLink partiallyActive={true} activeClassName="active" to={e.node.path || '#'}>
          {e.node.label}
        </StyledLink>
      </Box>
    );
  });
};

export const Footer = (): ReactElement => {
  const data: FooterInfoQuery = useStaticQuery(graphql`
    query FooterInfo {
      allFooterNavigationYaml {
        edges {
          node {
            id
            label
            path
          }
        }
      }
    }
  `);

  return <PureFooter data={data} />;
};

export const PureFooter = ({ data }: PureFooterProps): ReactElement => {
  const navDataEdge = useMemo(() => data.allFooterNavigationYaml.edges, [data]);

  return (
    <FooterWrap id="footer-content" data-testid="footer">
      <FooterContainer>
        <FooterMain>
          <TopRow>
            <LogoContainer>
              <StyledLink to="/" title="Delphia.com">
                <DelphiaWordMark />
                <ScreenReaderLabel>Home Page</ScreenReaderLabel>
              </StyledLink>
            </LogoContainer>
            <NavGridContainer>
              <NavGrid tag="ul">{renderNavItems(navDataEdge)}</NavGrid>
            </NavGridContainer>
          </TopRow>
          <SecondRow>
            <Grid colspan={[12]} className="grid">
              <Box cols={[12]} className="box">
                <FooterLegal>
                  <ul>
                    <li>
                      <Link to="/terms-of-service">Terms of Service</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                  </ul>
                </FooterLegal>
                <Copyright>&copy; {new Date().getFullYear()} Delphia Technologies Inc. All rights reserved.</Copyright>
              </Box>
            </Grid>
          </SecondRow>
        </FooterMain>
      </FooterContainer>
    </FooterWrap>
  );
};
