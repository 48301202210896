import styled from 'styled-components';
import { media } from '@cheese-fondue/helpers';

export const Logo = styled.img`
  max-height: 24px;
  max-width: 148px;

  ${media.medium`
    max-height: 20px;
    max-width: 120px;
  `}

  ${media.large`
    max-height: 24px;
    max-width: 148px;
  `}
`;
