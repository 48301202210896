import { typography } from '@cheese-fondue/styles/typography';
import styled, { css } from 'styled-components';
import { media } from '../../../helpers';
import { COLORS, SPACING } from '../../../styles';
import { LinkWrapper } from '../linkWrapper/linkWrapper';
import { ButtonProps } from './button-props';

type ButtonTypes = Omit<ButtonProps, 'title' | 'children' | 'onClick'>;

const borderWidth = 2;
const baselineFix = 2;
const sizes = {
  small: SPACING.two - 2 * borderWidth,
  default: SPACING.three - 2 * borderWidth,
  large: SPACING.four - 2 * borderWidth,
};

const backgroundColor = ({ secondary, light, black, filled, fillColor }: ButtonTypes): string => {
  let color = black
    ? COLORS.black
    : secondary
    ? COLORS.white
    : light
    ? COLORS.orange100
    : filled && fillColor
    ? fillColor
    : COLORS.blue200;
  color = secondary && light ? 'transparent' : color;
  return color;
};

const hoverBackgroundColor = ({ secondary, light, black, filled, hoverColor }: ButtonTypes): string => {
  let color = black
    ? COLORS.black
    : secondary
    ? COLORS.blue200
    : light
    ? COLORS.orange200
    : filled && hoverColor
    ? hoverColor
    : COLORS.blue400;
  color = secondary && light ? COLORS.orange100 : color;
  return color;
};

const borderColor = ({ secondary, light, black, filled, fillColor }: ButtonTypes): string => {
  let color = black ? COLORS.black : light ? COLORS.orange100 : filled && fillColor ? fillColor : COLORS.blue200;
  color = secondary && light ? COLORS.orange100 : color;
  return color;
};

const hoverBorderColor = ({ black, secondary, light, filled, hoverColor, hoverOutline }: ButtonTypes): string => {
  let color = black
    ? COLORS.black
    : secondary
    ? COLORS.blue200
    : light
    ? COLORS.orange200
    : filled && hoverColor && !hoverOutline
    ? hoverColor
    : filled && hoverOutline
    ? hoverOutline
    : COLORS.blue300;
  color = secondary && light ? COLORS.orange100 : color;
  return color;
};

const textColor = ({ secondary, light, filled, textColor }: ButtonTypes): string => {
  let color = secondary || light ? COLORS.blue200 : filled && textColor ? textColor : COLORS.white;
  color = secondary && light ? COLORS.orange100 : color;
  return color;
};

const hoverTextColor = ({ secondary, light, filled, hoverTextColor }: ButtonTypes): string => {
  const color =
    light || (secondary && light) ? COLORS.blue200 : filled && hoverTextColor ? hoverTextColor : COLORS.white;
  return color;
};

const svgFill = ({ secondary, light, black }: ButtonTypes): string => {
  let color = black ? COLORS.white : secondary ? COLORS.blue400 : light ? COLORS.blue200 : COLORS.orange100;
  color = secondary && light ? COLORS.orange100 : color;
  return color;
};

const svgHover = ({ secondary, light, black }: ButtonTypes): string => {
  const color = black ? COLORS.white : light ? COLORS.blue300 : light && secondary ? COLORS.blue300 : COLORS.orange100;
  return color;
};

const heightMixin = css`
  line-height: ${(props: ButtonTypes) => `${props.small ? sizes.small - baselineFix : sizes.default - baselineFix}px`};
  ${media.medium`
    line-height: ${(props: ButtonTypes) =>
      `${props.small ? sizes.small - baselineFix : props.large ? sizes.large : sizes.default}px`};
  `}
`;

const paddingSides = ({ small, large }: ButtonTypes): string => {
  const num = small ? SPACING.one : large ? SPACING.four : SPACING.one;
  return num + 'px';
};

const paddingSidesMobile = ({ small }: ButtonTypes): string => {
  const num = small ? SPACING.one : SPACING.two;
  return num + 'px';
};

const buttonBaseStyles = css`
  display: inline-block;
  cursor: pointer;
  ${typography.button}
  border-radius: 120px;
  font-size: ${(props): string => `${props.small ? 12 : 16}px`};
  letter-spacing: ${(props): string => `${props.small ? 1 : 2}px`};
  margin: 0;
  text-transform: ${props => (props.textTransform ? props.textTransform : 'uppercase')};
  transition: all 200ms ease-in;
  background-color: ${(props): string => backgroundColor(props)};
  border: ${borderWidth}px solid ${(props): string => borderColor(props)};
  color: ${(props): string => textColor(props)};
  text-align: center;

  padding-bottom: 0;
  padding-left: ${(props): string => paddingSidesMobile(props)};
  padding-right: ${(props): string => paddingSidesMobile(props)};

  ${props => heightMixin}

  ${media.medium`
    padding-left: ${(props): string => paddingSides(props)};
    padding-right: ${(props): string => paddingSides(props)};
  `}

  /* Add 2px padding top on mobile to adjust perceived vertical center. I do not particularly enjoy this... */
  padding-top: ${(props): string => `${props.small ? 2 : 0}px`};

  svg {
    position: relative;
    top: 1px;
    /* stylelint-disable */
    path {
      fill: ${(props): string => svgFill(props)};
      display: inline-block;
    }
  }

  &:focus,
  &:hover {
    outline: none;
    background: ${(props): string => hoverBackgroundColor(props)};
    border-color: ${(props): string => hoverBorderColor(props)};
    color: ${(props): string => hoverTextColor(props)};
    svg path {
      fill: ${(props): string => svgHover(props)};
    }
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.8;
    outline: unset;
    pointer-events: unset;
  }

  ${props =>
    props.block &&
    css`
      display: block;
      width: 100% !important;
    `}
`;

export const StyledButton = styled.button<ButtonTypes>`
  ${buttonBaseStyles}
`;

export const StyledButtonLink = styled(LinkWrapper)<ButtonTypes>`
  ${buttonBaseStyles}
  text-decoration: none;
`;

export const StyledA = styled.a<ButtonTypes>`
  ${buttonBaseStyles}
  text-decoration: none;
  text-transform: none;
`;

export const StyledAppLink = styled.a<ButtonTypes>`
  ${buttonBaseStyles}
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  width: 200px;
  margin: 8px;

  svg {
    height: 36px;
    width: auto;
    margin: 0;
    vertical-align: bottom;
  }
`;
