import { media } from '@cheese-fondue/helpers';
import { SIZES, COLORS, FONTS } from '@cheese-fondue/styles';
import styled from 'styled-components';

export interface NavComponentProps {
  readonly visible?: boolean;
  readonly blackOnPurple?: boolean;
  readonly whiteOnGreen?: boolean;
  readonly blueOnOrange?: boolean;
  readonly whiteOnBlue?: boolean;
}

export const NavWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: stretch; /* Default */
  justify-content: space-between;
  z-index: 1;
  height: 100%;
  max-width: ${SIZES.large}px;
  margin: 0 auto;
  padding: 0 2rem;
  ${media.medium`
    padding: 0 1rem;
    `}
  ${media.large`
    padding: 0 0;
    `}
`;

export const LogoContainer = styled.span<NavComponentProps>`
  margin: auto 0;
  display: initial;

  ${media.medium`
    height:100%;
    width:fit-content;
    left:0;
    right:0;
    top:0;
    display:flex;
    align-items:center;
  `}

  a {
    display: flex;
  }

  svg path {
    will-change: fill;
    transition: fill 0.3s ease ${props => (props.visible ? '0' : '1s')};
  }

  svg path:nth-child(n + 3) {
    display: none;

    ${media.medium`display: block;`};
  }
`;

export const SmallScreenNavWrapper = styled.div<NavComponentProps>`
  display: flex;

  ${media.medium`
    display: none;
  `}

  a {
    text-transform: none;
    font-family: ${FONTS.headings};
    padding-right: ${props => (props.visible ? '16px' : '4px')};
    border-radius: ${props => (props.visible ? '100em' : '100em 0 0 100em')};
    margin-right: ${props => (props.visible ? '15px' : '0')};
    border: none;
  }

  button {
    display: ${props => (props.visible ? 'none' : 'block')};
    border-radius: 0 100em 100em 0;
    padding: 0 16px 0 4px;
    font: inherit;
    cursor: pointer;
    border: none;

    svg {
      fill: ${props => (props.whiteOnGreen ? COLORS.black : COLORS.white)};
      height: 12px;
      width: 15px;
    }

    ${media.medium`display: none;`};
  }
`;

export const NavContainer = styled.nav<NavComponentProps>`
  display: flex;
  margin: auto 0;
`;

export const BigScreenNavWrapper = styled.div<NavComponentProps>`
  ${media.medium`display: flex;`}

  button {
    text-transform: none;
  }
  a {
    text-transform: none;
    font-family: ${FONTS.headings};
  }
  a:first-child {
    display: none;

    ${media.medium`
    display: block;
    margin-right: 15px;
    `};
  }

  a.secondary-button {
    display: ${props => (props.visible ? 'block' : 'none')};

    ${media.medium`
    display: block;
    `};
  }
`;

export const NavBackground = styled.div<NavComponentProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props =>
    props.blackOnPurple
      ? COLORS.purple100
      : props.whiteOnGreen
      ? COLORS.green300
      : props.blueOnOrange
      ? COLORS.orange300
      : props.whiteOnBlue
      ? COLORS.blue400
      : COLORS.white};
  z-index: -10;
  opacity: ${props => (props.visible ? 0 : 1)};
  transition: opacity 0.2s;

  ${media.medium`
    opacity: ${props => (props.visible && !props.blackOnPurple && !props.whiteOnGreen ? 0 : 1)};
  `}
`;
