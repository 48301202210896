import { WindowLocation } from '@reach/router';
import { motion, useViewportScroll } from 'framer-motion';
import { SmallHeaderInfoQuery } from 'graphql-types';
import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useSmallHeaderNavData } from './small-header-graphql-query';
import { NavBar } from '../navbar/navbar';

import { HeaderContainer } from './small-header-styling';

export interface Props {
  location: WindowLocation;
  whiteOnGreen?: boolean;
  blackOnPurple?: boolean;
  blueOnOrange?: boolean;
  whiteOnBlue?: boolean;
}
export interface PureHeaderProps extends Props {
  data: SmallHeaderInfoQuery;
}

export const SmallHeader = (props: Props): ReactElement => {
  const data = useSmallHeaderNavData();
  return <PureHeader {...props} data={data} />;
};

const excludeCurrentPage = (
  nodes: SmallHeaderInfoQuery['allSmallHeaderNavigationYaml']['edges'],
  currentPath: string,
): SmallHeaderInfoQuery['allSmallHeaderNavigationYaml']['edges'] => {
  return nodes.filter(node => {
    return node.node.path !== currentPath;
  });
};

export const PureHeader = ({
  location,
  whiteOnGreen = false,
  blackOnPurple = false,
  blueOnOrange = false,
  whiteOnBlue = false,
  data,
}: PureHeaderProps): ReactElement => {
  const posY = useRef(0);
  const [isDownThePage, setIsDownThePage] = useState(false);
  const { scrollY } = useViewportScroll();
  const threshold = 10;
  const [isNavToggled] = useState(false);

  const navItems = useMemo(() => data.allSmallHeaderNavigationYaml.edges, [data]);

  // trim trailing slash from pathname
  const currentPath =
    location.pathname.endsWith('/') && location.pathname.length > 2
      ? location.pathname.slice(0, -1)
      : location.pathname;

  // get secondary page
  // if this is neither index or vision, fallback to just home
  const secondaryPage = excludeCurrentPage(navItems, currentPath).slice(0, 1);
  useEffect(() => {
    return scrollY.onChange(v => {
      if (v === posY.current) return;
      setIsDownThePage(v > threshold);
      posY.current = v;
    });
  }, [threshold, posY, isDownThePage, scrollY]);

  useEffect(() => {
    if (isNavToggled) {
      document.body.classList.add('lockScroll');
    } else {
      document.body.classList.remove('lockScroll');
    }
  }, [isNavToggled]);

  return (
    <HeaderContainer id="header-content" as={motion.header} transition={{ ease: 'easeInOut' }}>
      <NavBar
        blackOnPurple={blackOnPurple}
        whiteOnGreen={whiteOnGreen}
        blueOnOrange={blueOnOrange}
        whiteOnBlue={whiteOnBlue}
        navItems={secondaryPage}
      />
    </HeaderContainer>
  );
};
