import { ScreenReaderLabel } from '@cheese-fondue/helpers/screen-reader-label';
import { COLORS } from '@cheese-fondue/styles';
import BackgroundImage, { IFluidObject } from 'gatsby-background-image';
import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
export interface ImageProps {
  altText?: string;
  imageData: IFluidObject;
  children: ReactNode;
}

interface BackgroundProps {
  ratio: number;
}
const StyledBackgroundImage = styled(BackgroundImage)<BackgroundProps>`
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  height: 100%;
  position: relative;
  width: 100%;
`;

export const BGImage = ({ imageData, altText = '', children, ...props }: ImageProps): ReactElement => {
  return (
    <StyledBackgroundImage
      ratio={imageData.aspectRatio}
      Tag="div"
      alt={altText}
      fluid={imageData}
      backgroundColor={COLORS.blue400}
      {...props}
    >
      <ScreenReaderLabel>{altText}</ScreenReaderLabel>
      {children}
    </StyledBackgroundImage>
  );
};
