import React, { ReactElement } from 'react';
import { Box as RasterBox, Types } from 'react-raster';
import styled from 'styled-components';
import { media } from '../../helpers';
import { SPACING } from '../../styles';
import { COLORS } from '../../styles/theme-constants';
import { typography } from '../../styles/typography';
import { Text } from '../atoms/text';
import { Grid } from './grid';

export enum AspectRatio {
  Free = 'free',
  OneByOne = 'oneByOne',
  TwoByOne = 'twoByOne',
  OneByTwo = 'oneByTwo',
  FourByOne = 'fourByOne',
}

export interface BoxTypes extends Types.Box {
  aspectRatio?: AspectRatio[]; // mapped to breakpoints [smalles, larger, largest...]
}

const aspects = {
  free: `
    position: static;
    width: 100%;

    &:before {
      content: unset;
      height: auto;
      display: initial;
      padding-top: unset;
      position: unset;
    }

    > div {
      position: static;
    }
  `,
  oneByOne: `
    position: relative;
    width: 100%;

    &:before {
      content: '';
      height: 0;
      display: block;
      padding-top: 100%;
      position: relative;
    }

    > div {
      position: absolute;
    }
  `,
  twoByOne: `
    position: relative;
    width: 100%;

    &:before {
      content: '';
      height: 0;
      display: block;
      padding-top: 50%;
      position: relative;
    }

    > div {
      position: absolute;
    }
  `,
  fourByOne: `
    position: relative;
    width: 100%;

    &:before {
      content: '';
      height: 0;
      display: block;
      padding-top: 25%;
      position: relative;
    }

    > div {
      position: absolute;
    }
  `,
  oneByTwo: `
    position: relative;
    width: 100%;

    &:before {
      content: '';
      height: 0;
      display: block;
      padding-top: 200%;
      position: relative;
    }

    > div {
      position: absolute;
    }
  `,
};

const BoxContentWrapper = styled.div`
  top: 0;
  width: 100%;
  height: 100%;
`;

export const Box = ({ aspectRatio = [], children, ...props }: BoxTypes): ReactElement => {
  const aspectCss = aspectRatio.map(a => aspects[a]);

  if (aspectCss.length === 0) return <RasterBox {...props}>{children}</RasterBox>;

  return (
    <RasterBox css={aspectCss} {...props}>
      <BoxContentWrapper>{children}</BoxContentWrapper>
    </RasterBox>
  );
};

export const SpeckledBox = styled(({ backgroundColor, ...rest }) => <Box {...rest} />)<{ backgroundColor?: string }>`
  ${({ theme, backgroundColor }) =>
    (theme.speckledBackground && theme.speckledBackground(backgroundColor ?? COLORS.orange100)) ??
    `background: ${backgroundColor};`};
`;

export const BoxWrapper = styled(Box)`
  grid-row-start: 2;
  grid-row-end: span 2;
  align-self: center;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  padding-bottom: 20px;
  background-position-x: center;
  padding-top: 64px;
  ${media.small`
    grid-column-start: 1;
    grid-column-end: 13;
    padding-left: 20px;
    padding-right: 20px;
  `}
  ${media.large`
    grid-column-start: 3;
    grid-column-end: 11;
    padding-left: 100px;
    padding-right: 100px;
  `}
`;

export const TextWrapper = styled.div`
  margin: 0 auto;
  ${media.small`
    max-width: none;
  `}
  ${media.large`
    max-width: 726px;
  `}
`;

export const StyledGrid = styled(Grid)`
  height: 100%;
  grid-template-rows: auto;
`;

export const StyledTitle = styled(Text).attrs({
  as: 'h2',
  mt: 'two',
  size: 'h3',
})<{ $titleDecorationImage: string }>`
  position: relative;
  text-align: center;
  &::before {
    position: absolute;
    bottom: 150%;
    left: 50%;
    transform: translate(-50%) rotate(180deg);
    content: '';
    width: 57px;
    height: 23px;
    background-image: url(${props => props.$titleDecorationImage});
    background-repeat: no-repeat;
    background-position-y: top;
  }
  &::after {
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%);
    content: '';
    width: 57px;
    height: 23px;
    background-image: url(${props => props.$titleDecorationImage});
    background-repeat: no-repeat;
    background-position-y: top;
  }
`;

export const StyledCopy = styled.ul<{ bulletImage: string }>`
  margin: 0;
  padding: 0;
  && li {
    ${typography.p};
    list-style: none;
    margin-left: ${SPACING.two}px;
    margin-bottom: ${SPACING.one}px;
    margin-right: ${SPACING.one}px;
    position: relative;

    ${media.medium`
      margin-right: 0;
    `}

    ${media.customBreak(900)`
      ${typography.pMedium};
    `}

    ${media.large`    
      margin-left: ${SPACING.three}px;
      margin-bottom: ${SPACING.two}px;
    `}

    &::before {
      background-image: url(${props => props.bulletImage});
      background-repeat: no-repeat;
      background-position-y: top;
      content: '';
      height: 28px;
      left: -${SPACING.three / 2}px;
      position: absolute;
      top: ${SPACING.one / 4}px;
      width: ${SPACING.base}px;
    }
  }
`;

export const renderBullets = (bullets: { title: string; content?: string }[]): React.ReactNode => {
  return bullets.map((bullet: { title: string; content?: string }, index: number) => {
    return (
      <li key={index} className="medium">
        <Text as="h4" size={['h4', 'h4']}>
          {bullet.title}
        </Text>
        {bullet.content ? (
          <Text as="p" size={'p'} mt="one">
            {bullet.content}
          </Text>
        ) : null}
      </li>
    );
  });
};
